import React from "react";

const Contact = () => {
  return (
    <>
      <section id="contact">
        <div className="contact__title--wrapper">
          <h2 className="contact__title">
            <span className="color__box">CONTACT US.</span>
          </h2>
          <p className="contact__description">
            We’re here to help and answer any question you might have. We look
            forward to hearing from you.
          </p>
        </div>
        <div class="formcarry-container">
          <form
            action="https://formcarry.com/s/2P_ZXcGUx_7"
            method="POST"
            enctype="multipart/form-data"
          >
            <div class="formcarry-block">
              <label for="fc-generated-1-name">Full Name</label>
              <input
                type="text"
                name="name"
                id="fc-generated-1-name"
                placeholder="Your first and last name"
              />
            </div>

            <div class="formcarry-block">
              <label for="fc-generated-1-email">Your Email Address</label>
              <input
                type="email"
                name="email"
                id="fc-generated-1-email"
                placeholder="john@doe.com"
              />
            </div>

            <div class="formcarry-block">
              <label for="fc-generated-1-message">Your message</label>
              <textarea
                name="message"
                id="fc-generated-1-message"
                placeholder="Enter your message..."
              ></textarea>
            </div>

            <div class="formcarry-block">
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
