import { React, useRef } from "react"
import { Link } from "react-router-dom";
import UTlogo from "../assets/Universal.svg"

function Nav() {

  function closeMenu() {
    const sidebar = document.querySelector('.sidebar');
    sidebar.style.display = ('none');
  }

  function openMenu() {
    const sidebar = document.querySelector('.sidebar');
    sidebar.style.display = ('flex');
  }
  
  return (
    <nav>
      <div className="nav__container">
        <Link to='/'>
          <img src={UTlogo} alt="" className="logo" />
        </Link>
        <ul className="sidebar">
          <li onClick={closeMenu}>
            <button className="nav__button">
              <svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 96 960 960" width="26">
                <path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/>
              </svg>
            </button>
          </li>
          <li className="nav__list">
            <Link to='/' className="nav__link">
              Home
            </Link>
          </li>
          <li className="nav__list">
            <Link to='/about' className="nav__link">
              About
            </Link>
          </li>
          <li className="nav__list">
            <Link to='/contact' className="nav__link">
              Contact Us
            </Link>
          </li>
        </ul>
        <ul>
          <li className="hideOnMobile">
            <Link to='/' className="nav__link">
              Home
            </Link>
          </li>
          <li className="hideOnMobile">
            <Link to='/about' className="nav__link">
              About
            </Link>
          </li>
          <li className="hideOnMobile">
            <Link to='/contact' className="nav__link">
              Contact Us
            </Link>
          </li>
          <li className="menu-button" onClick={openMenu}>
            <button className="nav__button">
              <svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 96 960 960" width="26">
                <path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z"/>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;