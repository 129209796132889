import React from "react"
import Drycleanimg from "../assets/drycleanimg.png"
import Embroideryimg from "../assets/embroideryimg.png"
import Tailoringimg from "../assets/tailoringimg.png"


const Features = () => {
    return (
        <section id="features">
            <div className="features__container">
                <div className="features__wrapper">
                    <h2 className="features__title">
                        Amazing <span className="color__box">Services.</span>
                    </h2>
                    <div className="services__wrapper">
                        <div className="service">
                            <div className="service__icon--wrapper">
                                <img src={Embroideryimg} alt="" className="service__icon"/>
                            </div>
                            <span className="service__title">
                                Embroidery
                            </span>
                            <p className="service__description">
                                Get high-quality, custom embroidery for all your needs.
                            </p>
                        </div>
                        <div className="service">
                            <div className="service__icon--wrapper">
                                <img src={Tailoringimg} alt="" className="service__icon"/>
                            </div>
                            <span className="service__title">
                                Tailoring
                            </span>
                            <p className="service__description">
                                Expert tailoring services for a perfect fit every time.
                            </p>
                        </div>
                        <div className="service">
                            <div className="service__icon--wrapper">
                                <img src={Drycleanimg} alt="" className="service__icon"/>
                            </div>
                            <span className="service__title">
                                Dry Cleaning
                            </span>
                            <p className="service__description">
                                Professional dry cleaning for a spotless, fresh look.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;