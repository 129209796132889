import React from "react";
import Landingimg from "../assets/landingimg.jpg";

const Landing = () => {
  return (
    <section id="landing">
      <header>
        <div className="header__container">
          <div className="header__description--wrapper">
            <h2 className="header__title">
              Custom Clothing for{" "}
              <span className="color__box">Every Style.</span>
            </h2>
            <p className="header__para">
              Welcome to Universal Tailor, where our skilled artisans create
              custom clothing that perfectly fits your unique style. Whether
              it's a bespoke suit or a tailored dress, we're here to craft
              garments that celebrate your individuality and make you look and
              feel your best.
            </p>
            <button className="landing__btn">Explore</button>
          </div>
          <div className="header__img--wrapper">
            <img src={Landingimg} alt="" className="header__img" />
          </div>
        </div>
      </header>
    </section>
  );
};

export default Landing;
