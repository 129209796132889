import React from "react";
import { Link } from "react-router-dom"
import UTlogo from "../assets/Universal.svg"


const Footer = () => {
    return (
        <footer>
            <div className="footer__container">
                <div className="footer__wrapper">
                    <Link to="/">
                        <figure className="footer__logo">
                            <img src={UTlogo} alt="" className="footer__logo--img" />
                        </figure>
                    </Link>
                    <div className="footer__info">
                    <p>1422 Howell Branch Rd, Winter Park, FL 32789</p>
                    <p>(407) - 740 - 0400</p>
                    </div>
                    <div className="footer__list">
                        <Link to="/" className="footer__link link__border">Home</Link>
                        <Link to="/about" className="footer__link link__border">About</Link>
                        <Link to="/contact" className="footer__link link__border">Contact Us</Link>
                    </div>
                    <div className="footer__copyright">
                        &copy; Copyright 2024, Universal Tailor. All Rights Reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer