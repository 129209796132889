import React from "react"

const Statement = () => {
    return (
    <section id="statement">
        <div className="statement__container">
            <div className="statement__wrapper">
                <div className="statement__title--wrapper">
                    <h2 className="statement__title">
                        Over 10,000 Projects Completed
                    </h2>
                    <p className="statement__title--description">
                        Dedicated to servicing the Winter Park area, promising to deliver exceptional craftsmanship and customer service to every customer.
                    </p>
                </div>
                <div className="statement__numbers--wrapper">
                    <div className="number__wrapper">
                        <div className="number">
                            35+
                        </div>
                        <div className="number__description">
                            Years of Experience
                        </div>
                    </div>
                    <div className="number__wrapper">
                        <div className="number">
                            3000+
                        </div>
                        <div className="number__description">
                            Clients
                        </div>
                    </div>
                    <div className="number__wrapper">
                        <div className="number">
                            100%
                        </div>
                        <div className="number__description">
                            Satisfaction
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )

}

export default Statement;