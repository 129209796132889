import React from "react";
import slides from '../data/carouselData.json';
import Features from "../components/features";
import Landing from "../components/landing";
import Statement from "../components/statement";
import Testimonials from "../components/testimonials";

const Home = () => {
  return (
    <>
      <Landing />
      <Statement />
      <Features />
      <Testimonials data={slides} />
    </>
  );
};

export default Home
