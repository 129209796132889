import React, { useState } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs"

const Testimonials = ({ data }) => {
  const [ slide, setSlide ] = useState(0);

const nextSlide = () => {
    setSlide(slide === data.slides.length - 1 ? 0 : slide + 1)
}

const prevSlide = () => {
    setSlide(slide === 0 ? data.slides.length - 1 : slide - 1)
}


  return (
    <section id="testimonials">
        <div className="testimonials__container">
            <div className="testimonials__small--header">
                <h2 className="testimonials__title">Our Clients Love Us.</h2>
                <p className="testimonials__description">
                    At Universal Tailor, our clients' perfect fit is our passion. We
                    pride ourselves on delivering exceptional craftsmanship and
                    personalized service. Hear from our satisfied customers about how
                    our custom tailoring and embroidery has transformed their wardrobes and enhanced
                    their confidence.
                </p>
            </div>
            <div className="testimonials__wrapper">
                <div className="testimonials__header">
                    <h2 className="testimonials__title">Our Clients <span className="color__box">Love Us.</span></h2>
                    <p className="testimonials__description">
                        At Universal Tailor, our clients' perfect fit is our passion. We
                        pride ourselves on delivering exceptional craftsmanship and
                        personalized service. Hear from our satisfied customers about how
                        our custom tailoring and embroidery has transformed their wardrobes and enhanced
                        their confidence.
                    </p>
                </div>
                <div className="carousel">
                    <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide} />
                        {data.slides.map((item, idx) => {
                            return <img src={item.src} alt={item.alt} key={idx} className={slide === idx ? "slide" : "slide slide-hidden"} />
                        })} 
                    <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide}/>
                    <span className="indicators">
                        {data.slides.map((_, idx) => {
                            return <button key={idx} onClick={() => setSlide(idx)} className={slide ===idx ? "indicator" : "indicator indicator-inactive"} ></button>
                        })}
                    </span>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Testimonials;
