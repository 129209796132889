import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom"
import Nav from "./components/nav";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Footer from "./components/footer";


function App() {
  return (
    <Router>
      <div className="app">
        <Nav />
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Footer />
      </div>
    </Router>
  ) 
}

export default App;
