import React from "react";

const About = () => {
  return (
    <section id="about">
      <div className="section__container">
        <div className="about__wrapper">
          <div className="about__img--wrapper">
            <img
              src="https://picsum.photos/id/36/600/400"
              alt=""
              className="about__img"
            />
          </div>
          <div className="about__description">
            <h5 className="about__header">ABOUT UNIVERSAL TAILOR</h5>
            <h2 className="about__title">
              Your Premier Bespoke Tailoring Experts
            </h2>
            <p className="about__para">
              Universal Tailor is a premier bespoke tailoring service known for
              its meticulous craftsmanship and personalized approach. By
              blending traditional techniques with modern design, we create
              custom garments that reflect each client’s unique style and ensure
              a sophisticated, perfect fit.
            </p>
          </div>
        </div>
        <div className="about__wrapper">
          <div className="about__img--wrapper">
            <img
              src="https://picsum.photos/id/37/600/400"
              alt=""
              className="about__img"
            />
          </div>
          <div className="about__description-right">
            <h5 className="about__header">LOCATION & HOURS</h5>
            <h2 className="about__title">How To Find Us</h2>

            <p className="about__para--address">
                1422 Howell Branch Rd, Winter Park, FL 32789
            </p>
            <p className="about__para">
                Monday : Closed
            </p>
            <p className="about__para">
                Tuesday - Friday : 9AM - 6:30PM
            </p>
            <p className="about__para">
                Saturday : 9AM - 3PM
            </p>
            <p className="about__para">
                Sunday : Closed
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
